<template>
  <div>
    <Card :class="editTitleable ? 'nodeContainer' : ''">
      <p slot="title" >
        <a v-if="sort" @dblclick="handleShowNodeSortModal()" style="border: 1px solid; border-radius: 10px; padding: 0px 5px">{{
          sort
        }}</a>
        <!-- <span @dblclick="editTitle(name)" :class="{ nodetitle: editable }" class="workplatform-title">
          {{ name }}
        </span> -->
        <span  class="workplatform-title">
          {{name||('第'+ sort +'步')}}
        </span>
      </p>
      <div slot="extra">
        <a @click="deleteNode" v-if="canDelete" class="text-red">删除 </a>
      </div>

      <Tag
        closable
        v-for="(user, i) in users"
        :key="i"
        @on-close="handleTagClosed(i)"
      >
        {{ user.name }}
      </Tag>
      <div style="padding-top: 10px"><a @click="handleTagAdd">添加用户</a></div>
    </Card>
    <UserPicker
      ref="picker"
      :users.sync="users"
      @addUser="handleAddUser"
      @removeUser="removeUser"
      :needGetChild="needGetChild"
      :publisherId="publisherId"
      :companyId="companyId"
    ></UserPicker>
    <Modal v-model="showNodeSortModal">
        <p slot="header">
            <span>调整节点位置</span>
        </p>
        <div class="p-l-10 p-r-10">
          <p class="p-t-10">请选择调整后节点位置</p>
      <RadioGroup v-model="newSort">
        <Radio v-for="(item, i) in nodeLength" :key="i" :label="item">{{ item }}</Radio>
      </RadioGroup>

      </div>
      <div slot="footer">
            <i-button type="primary"  @click="handleChangeSort"
            :loading="loadingSubmit">确认</i-button>
        </div>
    </Modal>
  </div>
</template>

<script>
import UserPicker from './UserPicker'
export default {
  components: {
    UserPicker
  },
  props: {
    id: {
      type: Number
    },
    sort: {
      type: Number
    },
    nodeLength: {
      type: Number
    },
    name: {
      type: String,
      default: ''
    },
    users: {
      type: Array
    },
    editable: {
      type: Boolean,
      default: false
    },
    editTitleable: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    companyId: {
      type: Number
    },
    publisherId: {
      type: Number
    },
    needGetChild: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showNodeSortModal: false,
      newSort: 0,
      loadingSubmit: false
    }
  },
  methods: {
    // 添加用户
    handleAddUser (user) {
      this.$emit('addUser', this.id, user)
    },
    removeUser (user) {
      const index = this.users.findIndex((x) => x.id === user.id)
      this.handleTagClosed(index)
    },
    handleTagClosed (userIndex) {
      this.$emit('removeUser', this.id, userIndex)
    },
    handleTagAdd () {
      this.$refs.picker.showModal()
    },
    editTitle () {
      const node = {
        name: this.name,
        id: this.id
      }
      this.$emit('editTitle', node)
    },
    deleteNode () {
      this.$emit('deleteNode', this.id)
    },
    handleShowNodeSortModal () {
      this.newSort = this.sort
      this.showNodeSortModal = true
    },
    handleChangeSort () {
      this.$emit('changeSort', { id: this.id, old: this.sort, newSort: this.newSort })
      this.showNodeSortModal = false
    }
  }
}
</script>

<style scoped>
.nodetitle:hover:after {
  /* background: #333; */
  background: rgba(223, 220, 220, 0.8);
  border-radius: 5px;
  bottom: 26px;
  color: #fff;
  font-size: 12px;
  left: 20%;
  padding: 5px 15px;
  z-index: 98;
  width: 220px;
}
/* content: "您可以双击以更改"; */

.nodeContainer {
  height: 150px;
}
</style>
