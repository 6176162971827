<style lang="less" scoped>
.info-card {
  height: 80px;
  color: #fff;
  line-height: 80px;
}
.info-card-title {
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.info-card-value {
  text-align: right;
  font-size: 28px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dataTable {
  width: 100%;
  th,
  td {
    height: 40px;
    border: 1px solid rgb(230, 230, 230);
    padding: 10px 15px;
  }
}
.dataTable-right-title {
  color: #fff;
  font-weight: bold;
  width: 2%;
  word-wrap: break-word;
}
</style>
<template>
  <Modal v-model="showUserPicker" width="1000">
    <p slot="header">
       <span class="workplatform-title">添加用户</span>
      </p>
    <Row class="p-b-10">
      <i-col span="24">
        <span class="title">切换用户公司 </span>
        <RadioGroup v-model="query.companyId">
          <Radio v-for="company in allowCompanys" :key="company.value" :label="company.value">{{company.name}}</Radio>
        </RadioGroup>
      </i-col>
    </Row>
    <Row :gutter="8" class="p-b-10">
      <i-col span="20">
         <i-input size="small" v-model="query.keyword" placeholder="关键字：用户名、姓名等"></i-input>
      </i-col>
       <i-col span="4">
          <Button size="small" icon="ios-search" type="primary" @click="searchHeadle">搜索</Button>
      </i-col>
    </Row>
    <i-table stripe  border size="small" :data="list" :columns="columns"></i-table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
        @on-change="changePage"
      ></Page>
    </div>
  </Modal>
</template>

<script>
import { getCompanyChild } from '@/api/os/company'
import { getUserPage } from '@/api/os/user'

export default {
  props: {
    users: {
      type: Array
    },
    companyId: {
      type: Number
    },
    publisherId: {
      type: Number
    },
    needGetChild: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showUserPicker: false,
      allowCompanys: [], // 允许查询的公司清单

      total: 0,
      query: {
        keyword: '',
        companyId: 0,
        pageSize: 10,
        pageNumber: 1
      },
      list: [],
      columns: [
        { title: '用户名', key: 'username', width: 120, align: 'center' },
        { title: '姓名', key: 'name', width: 120, align: 'center' },
        {
          title: '工作台角色',
          align: 'center',
          render: (h, parmas) => {
            const roles = []
            parmas.row.stationRole.forEach(item => {
              roles.push(h('Tag', {
                props: {
                  color: 'cyan'
                }
              }, item.name))
            })
            return h('div', roles)
          }
        },
        {
          title: '操作',
          width: 100,
          render: (h, data) => {
            if (this.users && this.users.some(x => x.id === data.row.id)) {
              return h('a', {
                style: {
                  color: '#ef4f4f'
                },
                on: {
                  click: () => {
                    this.$emit('removeUser', data.row)
                  }
                }
              }, '移除')
            } else {
              return h('a', {
                props: {
                  type: 'success',
                  size: 'small'
                },
                on: {
                  click: () => {
                    this.$emit('addUser', data.row)
                  }
                }
              }, '添加')
            }
          }
        }
      ]
    }
  },
  methods: {
    showModal () {
      this.showUserPicker = true
    },
    loadAllowCompanys () {
      this.allowCompanys = [
        {
          name: '本公司',
          value: this.companyId
        }
      ]

      if (this.needGetChild) { // 需要获取子公司
        this.loadCompanyAndChildCompany()
      } else if (this.companyId !== this.publisherId) { // 本身不是业主，需要获取上级公司
        const postData = {
          type: 1,
          enabled: true,
          companyId: this.publisherId
        }

        getCompanyChild(postData).then(res => {
          const currentLevelIndex = this.checkChildCompanys(res.children, [1])
          const level = currentLevelIndex[0]
          let companyIndex = 0
          let currentCompanyBean = res

          for (let index = 0; index < level; index++) {
            // 如果允许的清单不包含该公司，那么就添加
            if (!this.allowCompanys.find(x => x.value === currentCompanyBean.companyId)) {
              this.allowCompanys.push({ name: currentCompanyBean.companyName, value: currentCompanyBean.companyId })
            }

            companyIndex = currentLevelIndex[index + 1]
            currentCompanyBean = currentCompanyBean.children[companyIndex]
          }

          this.searchHeadle()
        })
      } else {
        this.searchHeadle()
      }
    },
    loadCompanyAndChildCompany () {
      const postData = {
        enabled: true,
        companyId: this.companyId,
        type: this.publisherId === this.companyId ? 1 : 2
      }

      getCompanyChild(postData).then(res => {
        res.children.forEach(element => {
          // 如果允许的清单不包含该公司，那么就添加
          if (!this.allowCompanys.find(x => x.value === element.companyId)) {
            this.allowCompanys.push({ name: element.companyName, value: element.companyId })
          }
        })

        this.searchHeadle()
      })
    },
    checkChildCompanys (companyChildrenBean, levelIndex) {
      for (let index = 0; index < companyChildrenBean.length; index++) {
        const checkLevelIndex = [].concat(levelIndex)
        if (companyChildrenBean[index].companyId === this.companyId) {
          checkLevelIndex.push(index)
          return checkLevelIndex
        }

        checkLevelIndex[0] += 1
        checkLevelIndex.push(index)
        const newVal = this.checkChildCompanys(companyChildrenBean[index].children, checkLevelIndex)

        if (newVal) {
          return newVal
        }
      }
    },
    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.initUserPage()
    },
    initUserPage () {
      getUserPage(this.query).then(data => {
        this.list = data.list
        this.total = data.totalRow
      })
    },
    searchHeadle () {
      this.query.pageNumber = 1
      this.initUserPage()
    }
  },
  created () {
    this.query.companyId = this.companyId
    this.loadAllowCompanys()
  },
  watch: {
    'query.companyId' (val) {
      this.searchHeadle()
    }
  }
}
</script>
